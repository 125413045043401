<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="article-product-modal">
      <v-card-title>
        <v-btn fab dark color="info" x-small text @click="closeModal">
          <IconPictoAarrowsLeft />
        </v-btn>
        <span class="article-product-modal__title"
          >J’ai mon propre parquet</span
        >
        <v-btn fab dark color="info" x-small text @click="closeModal">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="article-product-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form-custom-product"
          >
            <div v-if="formData.product" class="form__body">
              <v-text-field
                v-model="formData.product.name"
                outlined
                label="Nom du fournisseur*"
                type="text"
                persistent-placeholder
                dense
                placeholder="Par2507-56"
                :rules="[validations.required]"
              ></v-text-field>
              <v-text-field
                v-model="formData.product.mark"
                outlined
                label="Marque*"
                type="text"
                persistent-placeholder
                dense
                placeholder="Marque du produit"
                :rules="[validations.required]"
              ></v-text-field>
              <v-text-field
                v-model="formData.product.reference"
                outlined
                label="Référence Produit"
                type="text"
                persistent-placeholder
                dense
                placeholder="25054PA65"
              ></v-text-field>
              <v-text-field
                v-model.number="formData.product.client_price"
                outlined
                label="Prix du produit"
                type="text"
                persistent-placeholder
                dense
                placeholder="Prix"
                :rules="[
                  formData.product.client_price ? validations.numeric : true,
                ]"
              ></v-text-field>
              <v-text-field
                v-model.trim="formData.product.site_url"
                outlined
                label="Lien site-internet"
                type="url"
                persistent-placeholder
                dense
                placeholder="example.com"
                :rules="[
                  formData.product.site_url ? validations.siteUrl : true,
                ]"
              ></v-text-field>
              <v-text-field
                v-if="$store.getters['authModule/isSuperUser']"
                v-model.number="formData.product.price"
                outlined
                label="Prix d'achat"
                type="text"
                persistent-placeholder
                dense
                placeholder="Prix"
                :rules="[formData.price ? validations.numeric : true]"
              ></v-text-field>
              <v-textarea
                v-model="formData.product.description"
                outlined
                label="Vos commentaires"
                type="text"
                persistent-placeholder
                dense
                placeholder=""
              ></v-textarea>
              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                  :loading="loading"
                >
                  VALIDER
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
import {
  CustomProductsService,
} from "@/services/product.service.js";
export default {
  name: "ArticleOfProductModal",
  props: {
    show: {
      type: Boolean,
    },
    model: {
      type: Object,
    },
    parentValid: {
      type: Boolean,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formData: {
        error: false,
        save: false,
        product: {
          name: "",
          price: null,
          client_price: null,
          mark: "",
          reference: "",
          description: "",
          site_url: "",
        },
      },
      valid: true,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
    model: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return
        this.formData = Object.assign({}, {
          product: {
            ...newVal,
            client_price: newVal.client_price || null,
            price: newVal.price || null
          },
          save: false,
          error: false
        });
      },
    },
  },
  methods: {
    prepareData(save = false) {
      return {
        error: !this.valid,
        save,
        product: {
          id: this.model?.id || null,
          name: this.formData.product.name,
          description: this.formData.product.description || "",
          reference: this.formData.product.reference || "",
          mark: this.formData.product.mark || "",
          price: this.formData.product.price ? this.formData.product.price : null,
          client_price: typeof this.formData.product.client_price === 'number' ? this.formData.product.client_price : null,
          actionType: this.model?.id ? "updateProduct" : "createProduct",
          site_url: this.formData.product.site_url,
        }
      }
    },
    submit() {
      this.$refs["form-custom-product"].validate();
      if (!this.valid && !this.parentValid) return;
      this.$emit("actionCustomProduct", this.prepareData(true));

      if (this.model?.id) {
        this.updateCustomProduct(this.prepareData(true)?.product)
      }

      this.$emit('closeModal')
    },
    closeModal() {
      this.$refs["form-custom-product"].validate();
      this.$emit("actionCustomProduct", this.prepareData(true));
      this.$emit('closeModal')
    },
    updateCustomProduct(payload) {
      if (!payload) return
      this.loading = true
      const {
        name,
        description,
        reference,
        mark,
        price,
        client_price,
        id,
        site_url,
      } = payload;
      CustomProductsService.updateProduct(id, {
          name,
          description,
          reference,
          mark,
          price,
          client_price,
          site_url,
        })
          .then(({ data }) => {
            this.$store.commit(
              "snackbarModule/SET_SUCCESS_MESSAGE",
              this.$t("snackbar.success.update")
            );
          })
          .catch(() => {
            this.$store.commit("snackbarModule/SET_ERROR_API", this.$t("snackbar.error.base"))
          })
          .finally(() => {
            this.$emit('updateCustomProduct')
            this.loading = false;
          });
    }
  },
};
</script>

<style src="./ArticleOfProductModal.scss" lang="scss" scoped></style>